@font-face {
    font-family: "MullerBlack";
    src: local('MullerBlack'), url('./fonts/MullerBlack.ttf') format('truetype');
}

@font-face {
    font-family: "MullerBold";
    src: local('MullerBold'), url('./fonts/MullerBold.ttf') format('truetype');
}

@font-face {
    font-family: "Muller";
    src: local('MullerRegular'), url('./fonts/MullerRegular.ttf') format('truetype');
}

@font-face {
    font-family: "MullerLight";
    src: local('MullerLight'), url('./fonts/MullerLight.ttf') format('truetype');
}

.MuiCheckbox-colorPrimary.Mui-checked {
    color: #06d106 !important
}

.MuiCheckbox-root .MuiIconButton-label > .MuiSvgIcon-root {
    fill: #fff      
}